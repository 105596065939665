import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
} from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { DatePickerInput } from "@mantine/dates";
import { MS_EXCEL_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import { isNotEmpty, useForm } from "@mantine/form";
import get from "lodash/get";
import omit from "lodash/omit";
import { useCallback } from "react";

import Dropzone from "../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import {
  CreateCreditUnderwritingInput,
  CuAnalysisResult,
  CuInsuranceResult,
  CuResultCategory,
  FileResult,
} from "../../../graphql/generated.ts";
import {
  CreditUnderwriting,
  cuAnalysisResultOptions,
  cuCancelSubmissionOptions,
  cuInsuranceResultOptions,
  cuLoiResultCategoryOptions,
  cuLoiResultOptions,
  cuResultCategoryOptions,
} from "../../../types/creditUnderwriting.ts";
import {
  LimitRequestDetail,
  pricingApprovedMapping,
  tenorOptions,
} from "../../../types/limitRequest/limitRequest.ts";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";

export type CreditUnderwritingModalSubmitValue = Omit<
  CreateCreditUnderwritingInput,
  "id" | "limitRequestId"
>;

interface Props {
  value: CreditUnderwriting | null | undefined;
  limitRequest:
    | Pick<LimitRequestDetail, "cuAssigned" | "cuAssignedDate">
    | null
    | undefined;
  assigneesOptions: SelectItem[];
  opened: boolean;
  onClose: () => void;
  onSubmit: (
    assignedId: number | undefined,
    assignedDate: Date | undefined,
    value: CreditUnderwritingModalSubmitValue
  ) => void;
}

const CreditUnderwritingModal = ({
  value,
  limitRequest,
  assigneesOptions,
  opened = false,
  onClose,
  onSubmit,
}: Props) => {
  const isCancelSubmissionOrRejected = useCallback(
    (values: Record<string, unknown>) => {
      return (
        values.analysisResult === CuAnalysisResult.CancelSubmission ||
        values.analysisResult === CuAnalysisResult.Rejected
      );
    },
    []
  );

  const form = useForm({
    initialValues: {
      assignedDate: limitRequest?.cuAssignedDate
        ? new Date(limitRequest.cuAssignedDate)
        : undefined,
      assignedId: limitRequest?.cuAssigned?.id,
      clikResultReviewDate: value?.clikResultReviewDate
        ? new Date(value.clikResultReviewDate)
        : undefined,
      clikResultCategory: value?.clikResultCategory,
      loiResultDate: value?.loiResultDate
        ? new Date(value.loiResultDate)
        : undefined,
      loiResultFile: value?.loiResultFile,
      loiResultCategory: value?.loiResultCategory,
      loiResult: value?.loiResult,
      insuranceRequestDate: value?.insuranceRequestDate
        ? new Date(value.insuranceRequestDate)
        : undefined,
      insuranceResultDate: value?.insuranceResultDate
        ? new Date(value.insuranceResultDate)
        : undefined,
      insuranceResult: value?.insuranceResult,
      insuranceLimitApproved: value?.insuranceLimitApproved
        ? Number(value.insuranceLimitApproved)
        : undefined,
      visitDate: value?.visitDate ? new Date(value.visitDate) : undefined,
      documentCollectionStartDate: value?.documentCollectionStartDate
        ? new Date(value.documentCollectionStartDate)
        : undefined,
      documentCompletedDate: value?.documentCompletedDate
        ? new Date(value.documentCompletedDate)
        : undefined,
      analysisResultDate: value?.analysisResultDate
        ? new Date(value.analysisResultDate)
        : undefined,
      analysisResult: value?.analysisResult,
      analysisFile: value?.analysisFile,
      limitRecommendation: value?.limitRecommendation
        ? Number(value.limitRecommendation)
        : undefined,
      tenorRecommendation: value?.tenorRecommendation
        ? String(value.tenorRecommendation)
        : undefined,
      pricingRecommendation: value?.pricingRecommendation
        ? Number(value.pricingRecommendation)
        : undefined,
      cancelSubmissionReason: value?.cancelSubmissionReason,
      notes: value?.notes,
    },
    validate: {
      assignedDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Assigned Date is required")(value),
      assignedId: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Assigned is required")(value),
      clikResultReviewDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CLIK Result Review Date is required")(value),
      clikResultCategory: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CLIK Result Category is required")(value),
      loiResultCategory: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("LOI Result Category is required")(value),
      insuranceRequestDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Insurance Request Date is required")(value),
      insuranceResultDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Insurance Result Date is required")(value),
      insuranceResult: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Insurance Result is required")(value),
      insuranceLimitApproved: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Insurance Limit Recommended is required")(value),
      visitDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Visit Date is required")(value),
      documentCollectionStartDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Document Collection Date is required")(value),
      documentCompletedDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("Document Completed Date is required")(value),
      analysisResultDate: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Analysis Result Date is required")(value),
      analysisResult: isNotEmpty("CU Analysis Result is required"),
      analysisFile: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Analysis is required")(value),
      limitRecommendation: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Limit Recommendation is required")(value),
      tenorRecommendation: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Tenor Recommendation is required")(value),
      pricingRecommendation: (value, values) =>
        isCancelSubmissionOrRejected(values)
          ? null
          : isNotEmpty("CU Pricing Recommendation is required")(value),
      cancelSubmissionReason: (value, values) =>
        values.analysisResult === CuAnalysisResult.CancelSubmission
          ? isNotEmpty("CU Cancel Submission Reason is required")(value)
          : null,
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit(
      form.values.assignedId,
      form.values.assignedDate,
      omit(
        {
          ...form.values,
          loiResultFile: form.values.loiResultFile?.filePath ?? null,
          analysisFile: form.values.analysisFile?.filePath,
          tenorRecommendation: Number(form.values.tenorRecommendation),
        },
        "assignedId",
        "assignedDate"
      ) as CreditUnderwritingModalSubmitValue
    );
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (
    name: keyof CreditUnderwriting,
    files: FileResult[]
  ) => {
    if (name === "loiResultFile") {
      form.setValues({ loiResultFile: files[0] });
    }
    if (name === "analysisFile") {
      form.setValues({ analysisFile: files[0] });
    }
  };

  const handleRemoveFile = (name: keyof CreditUnderwriting) => {
    if (name === "loiResultFile") {
      form.setValues({ loiResultFile: undefined });
    }
    if (name === "analysisFile") {
      form.setValues({ analysisFile: undefined });
    }
  };

  const handleAssignCU = (value: number) => {
    form.setValues({
      assignedId: value,
      assignedDate: new Date(),
    });
  };

  const handleSelectClikResultCategory = (value: CuResultCategory) => {
    form.setValues({
      clikResultCategory: value,
      clikResultReviewDate: new Date(),
    });
  };

  const handleSelectInsuranceResult = (value: CuInsuranceResult) => {
    form.setValues({
      insuranceResult: value,
      insuranceResultDate: new Date(),
    });
  };

  const handleSelectAnalysisResult = (value: CuAnalysisResult) => {
    form.setValues({
      analysisResult: value,
      analysisResultDate: new Date(),
      cancelSubmissionReason: null,
    });
  };

  const handleSelectTenor = (value: string | null) => {
    form.setValues((prevValues) => {
      return {
        ...prevValues,
        tenorRecommendation: value ?? undefined,
        pricingRecommendation: value
          ? get(pricingApprovedMapping, Number(value))
          : undefined,
      };
    });
  };

  return (
    <>
      <Modal
        opened={opened}
        title={value ? "Edit Credit Underwriting" : "Add Credit Underwriting"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <SimpleGrid cols={2} spacing={20} verticalSpacing={8}>
            <Flex gap={12} direction="column">
              <DatePickerInput
                label="CU Assigned Date"
                placeholder="Select CU Assigned Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                disabled={!form.values.assignedDate}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("assignedDate")}
              />
              <Select
                withinPortal
                required={!isCancelSubmissionOrRejected(form.values)}
                data={assigneesOptions}
                label="CU Assigned"
                placeholder="Select CU Assigned"
                size="m"
                value={
                  form.values.assignedId ? String(form.values.assignedId) : null
                }
                onChange={(value) => handleAssignCU(Number(value))}
              />
              <DatePickerInput
                label="CLIK Result Review Date"
                placeholder="Select CLIK Result Review Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                disabled={!form.values.clikResultReviewDate}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("clikResultReviewDate")}
              />
              <Select
                withinPortal
                required={!isCancelSubmissionOrRejected(form.values)}
                data={cuResultCategoryOptions}
                label="CLIK Result Category"
                placeholder="Select CLIK Result Category"
                size="m"
                {...form.getInputProps("clikResultCategory")}
                onChange={(value) =>
                  handleSelectClikResultCategory(value as CuResultCategory)
                }
              />
              <DatePickerInput
                label="LOI Result Date"
                placeholder="Select LOI Result Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("loiResultDate")}
              />
              <Input.Wrapper label="LOI Result" size="m" w="100%">
                <Dropzone
                  maxFiles={1}
                  accept={[...PDF_MIME_TYPE, ...MS_EXCEL_MIME_TYPE]}
                  subLabel="Only .pdf, .xls, .xlsx files up to 5MB are allowed"
                  value={
                    form.values.loiResultFile
                      ? [form.values.loiResultFile]
                      : null
                  }
                  onUploadSuccess={(value) =>
                    handleFileUploaded("loiResultFile", value)
                  }
                  onRemove={() => handleRemoveFile("loiResultFile")}
                />
              </Input.Wrapper>
              <Select
                withinPortal
                required={!isCancelSubmissionOrRejected(form.values)}
                data={cuLoiResultCategoryOptions}
                label="LOI Result Category"
                placeholder="Select LOI Result Category"
                size="m"
                {...form.getInputProps("loiResultCategory")}
              />
              <Select
                withinPortal
                data={cuLoiResultOptions}
                label="LOI Result"
                placeholder="Select LOI Result"
                size="m"
                {...form.getInputProps("loiResult")}
              />
              <DatePickerInput
                label="Insurance Request Date"
                placeholder="Select Insurance Request Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                required={!isCancelSubmissionOrRejected(form.values)}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("insuranceRequestDate")}
              />
              <Select
                withinPortal
                required={!isCancelSubmissionOrRejected(form.values)}
                data={cuInsuranceResultOptions}
                label="Insurance Result"
                placeholder="Select Insurance Result"
                size="m"
                {...form.getInputProps("insuranceResult")}
                onChange={(value) =>
                  handleSelectInsuranceResult(value as CuInsuranceResult)
                }
              />
              <DatePickerInput
                label="Insurance Result Date"
                placeholder="Select Insurance Result Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                disabled={!form.values.insuranceResultDate}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("insuranceResultDate")}
              />
              <NumberInput
                label="Insurance Limit Recommended"
                placeholder="Enter Insurance Limit Recommended"
                required={!isCancelSubmissionOrRejected(form.values)}
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                hideControls
                {...form.getInputProps("insuranceLimitApproved")}
              />
            </Flex>
            <Flex gap={12} direction="column">
              <DatePickerInput
                label="Visit Date"
                placeholder="Select Visit Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                clearable
                required={!isCancelSubmissionOrRejected(form.values)}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("visitDate")}
              />
              <DatePickerInput
                label="Document Collection Date"
                placeholder="Select Document Collection Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                clearable
                required={!isCancelSubmissionOrRejected(form.values)}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("documentCollectionStartDate")}
              />
              <DatePickerInput
                label="Document Completed Date"
                placeholder="Select Document Completed Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                required={!isCancelSubmissionOrRejected(form.values)}
                popoverProps={{ withinPortal: true, zIndex: 1000 }}
                {...form.getInputProps("documentCompletedDate")}
              />
              <Select
                withinPortal
                required
                data={cuAnalysisResultOptions}
                label="CU Analysis Result"
                placeholder="Select CU Analysis Result"
                size="m"
                {...form.getInputProps("analysisResult")}
                onChange={(value) =>
                  handleSelectAnalysisResult(value as CuAnalysisResult)
                }
              />
              {form.values.analysisResult ===
                CuAnalysisResult.CancelSubmission && (
                <Select
                  withinPortal
                  required
                  data={cuCancelSubmissionOptions}
                  label="CU Cancel Submission Reason"
                  placeholder="Select CU Cancel Submission Reason"
                  size="m"
                  {...form.getInputProps("cancelSubmissionReason")}
                />
              )}
              <DatePickerInput
                label="CU Analysis Result Date"
                placeholder="Select CU Analysis Result Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                disabled={!form.values.analysisResultDate}
                {...form.getInputProps("analysisResultDate")}
              />
              <Input.Wrapper
                required={!isCancelSubmissionOrRejected(form.values)}
                label="CU Analysis"
                size="m"
                w="100%"
              >
                <Dropzone
                  maxFiles={1}
                  accept={[...PDF_MIME_TYPE, ...MS_EXCEL_MIME_TYPE]}
                  subLabel="Only .pdf, .xls, .xlsx files up to 5MB are allowed"
                  value={
                    form.values.analysisFile ? [form.values.analysisFile] : null
                  }
                  onUploadSuccess={(value) =>
                    handleFileUploaded("analysisFile", value)
                  }
                  onRemove={() => handleRemoveFile("analysisFile")}
                />
              </Input.Wrapper>
              <NumberInput
                label="CU Limit Recommendation"
                placeholder="Enter CU Limit Recommendation"
                required={!isCancelSubmissionOrRejected(form.values)}
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                hideControls
                {...form.getInputProps("limitRecommendation")}
              />
              <Select
                required={!isCancelSubmissionOrRejected(form.values)}
                size="m"
                label="CU Tenor Recommendation"
                placeholder="Enter CU Tenor Recommendation"
                data={tenorOptions}
                {...form.getInputProps("tenorRecommendation")}
                onChange={(value) => handleSelectTenor(value)}
              />
              <NumberInput
                label="CU Pricing Recommendation"
                placeholder="Enter CU Pricing Recommendation"
                required={!isCancelSubmissionOrRejected(form.values)}
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                hideControls
                {...form.getInputProps("pricingRecommendation")}
              />
              <Textarea
                label="Notes"
                placeholder="Enter Notes"
                autosize
                size="m"
                minRows={3}
                maxRows={3}
                {...form.getInputProps("notes")}
              />
            </Flex>
          </SimpleGrid>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default CreditUnderwritingModal;
