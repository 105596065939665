import { Flex, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../components/Icons/Edit.tsx";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip.tsx";
import Spoiler from "../../../../components/Spoiler/Spoiler.tsx";
import { TableEmptyValue } from "../../../../components/Table/constants.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import {
  ClikResultStatus,
  RolePermission,
} from "../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import { ClikResult } from "../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { hasPermission } from "../../../../utils/user.ts";
import { useStyles } from "../ClikResultList.styles.ts";
import { pendingTooltipLabel, requestTypes } from "./constants.ts";

interface Props {
  data: ClikResult[] | null | undefined;
  onEdit: (id: number) => void;
  onOpenDetails: (id: number) => void;
}

export function useClikResultTableData({
  data,
  onEdit,
  onOpenDetails,
}: Props): TableData {
  const { classes } = useStyles();

  const { user: currentUser } = useCurrentUserContext();

  const hasEditClikResultAccess = hasPermission(
    currentUser,
    RolePermission.CanEditClikResult
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikResult",
            fieldName: "createdAt",
          }}
        >
          Enquiry Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 160,
    },
    {
      accessor: "enquiryType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikResult",
            fieldName: "enquiryType",
          }}
        >
          Type of Enquiry
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "170px",
    },
    {
      accessor: "requestType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikResult",
            fieldName: "requestType",
          }}
        >
          Type of Request
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "name",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikData",
            fieldName: "name",
          }}
        >
          Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "320px",
    },
    {
      accessor: "status",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikResult",
            fieldName: "clikStatus",
          }}
        >
          Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "250px",
    },
    {
      accessor: "view",
      title: (
        <Flex align="center">
          <Text>Clik Result</Text>
          <InfoTooltip tooltipText={pendingTooltipLabel} />
        </Flex>
      ),
    },
    {
      accessor: "internalNote",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikResult",
            fieldName: "internalNote",
          }}
        >
          Internal Note
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "verdict",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikResult",
            fieldName: "verdict",
          }}
        >
          Verdict
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "actions",
      title: "Actions",
      hidden: !hasEditClikResultAccess,
    },
  ];

  const renderStatus = useCallback((item: ClikResult) => {
    if (item.clikStatus !== ClikResultStatus.Fail || !item.clikStatusDetails) {
      return toHumanReadable(item.clikStatus);
    }
    if (item.clikStatusDetails.type === "External") {
      const text =
        `${item.clikStatusDetails.text}. Please contact CLIK support.`
          .replace(/\.\./g, ".")
          .replace(/undefined./g, "");
      return (
        <Flex align="center">
          Failed. <InfoTooltip tooltipText={text} />
        </Flex>
      );
    }
    return (
      <Flex align="center">
        Failed.{" "}
        <InfoTooltip tooltipText="Internal ICE error. Please contact support." />
      </Flex>
    );
  }, []);

  const handleEdit = useCallback(
    (id: number) => {
      onEdit && onEdit(id);
    },
    [onEdit]
  );

  const handleOpenClikResultDetails = useCallback(
    (id: number | undefined | null) => {
      if (!id) {
        return;
      }
      onOpenDetails(id);
    },
    [onOpenDetails]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        createdAt: toLocalDate(item.createdAt),
        enquiryType: item.enquiryType && toHumanReadable(item.enquiryType),
        requestType: requestTypes[item.requestType],
        name: item.clikData?.name,
        status: renderStatus(item),
        view:
          item.clikStatus === ClikResultStatus.Success ? (
            <Text
              className={classes.clikResultViewText}
              onClick={() => handleOpenClikResultDetails(item.id)}
            >
              View
            </Text>
          ) : (
            TableEmptyValue
          ),
        internalNote: item?.internalNote && (
          <Spoiler text={item.internalNote} />
        ),
        verdict: item.verdict && toHumanReadable(item.verdict),
        actions: (
          <ActionIcon onClick={() => handleEdit(item.id)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
      return row;
    });
  }, [
    data,
    renderStatus,
    classes.clikResultViewText,
    handleOpenClikResultDetails,
    handleEdit,
  ]);

  return [columns, rows];
}
