import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { isEmpty, pickBy } from "lodash";

import { DateRangeFilterInput } from "../graphql/generated.ts";
import { LimitRequestFilter } from "../graphql/generated.ts";
import { LimitRequestFiltersValue } from "../types/filter.ts";
import {
  getDateString,
  getEndOfDayString,
  getStartOfDayString,
} from "./date.ts";

export const getFiltersCount = (filter: Record<string, unknown>) =>
  Object.values(filter).filter((prop) => !isEmpty(prop)).length;

export const getLimitRequestApiFilter = (
  value: LimitRequestFiltersValue
): LimitRequestFilter => {
  const companyFilter = pickBy({
    id: value?.companyName && { equals: Number(value.companyName) },
    salesName: value?.salesName && { equals: value.salesName },
    salesTeamName: value?.salesTeamName && { equals: value.salesTeamName },
  });
  const limitDetailFilter = pickBy({
    limitType: value?.limitType && {
      equals: value.limitType,
    },
  });

  return pickBy({
    requestStatus: value?.requestStatus && { equals: value.requestStatus },
    cuAssignedId: value?.cuAssignedId && { equals: value.cuAssignedId },
    rrAssignedId: value?.rrAssignedId && { equals: value.rrAssignedId },
    company: isEmpty(companyFilter) ? undefined : companyFilter,
    limitDetail: isEmpty(limitDetailFilter) ? undefined : limitDetailFilter,
  });
};

export const getDateRangeFilter = (
  value: DatesRangeValue | undefined
): DateRangeFilterInput | undefined => {
  if (!value) {
    return undefined;
  }
  if (!value[0] || !value[1]) {
    return undefined;
  }
  return {
    gte: getDateString(value[0]),
    lte: getDateString(value[1]),
  };
};

export const getDateTimeRangeFilter = (
  value: DatesRangeValue | undefined
): DateRangeFilterInput | undefined => {
  if (!value) {
    return undefined;
  }
  if (!value[0] || !value[1]) {
    return undefined;
  }
  return {
    gte: getStartOfDayString(value[0]),
    lte: getEndOfDayString(value[1]),
  };
};
