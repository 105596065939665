import { Flex } from "@mantine/core";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { omit } from "lodash";
import isNil from "lodash/isNil";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SvgWarning from "../../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  CollateralFilter,
  CollateralOrderBy,
  CreateCollateralInput,
  GiroType,
  OrderBy,
  RolePermission,
  UpdateCollateralInput,
} from "../../../../graphql/generated.ts";
import { useCollaterals } from "../../../../hooks/api/collateral/useCollaterals.ts";
import { useCreateCollateral } from "../../../../hooks/api/collateral/useCreateCollateral.ts";
import { useUpdateCollateral } from "../../../../hooks/api/collateral/useUpdateCollateral.ts";
import { useGetOlNumbersList } from "../../../../hooks/api/offerLetter/useGetOlNumbersList.ts";
import { useGetSoNumbersList } from "../../../../hooks/api/transactionHistory/useGetSoNumbersList.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import {
  Collateral,
  CollateralModalType,
  RawCollateral,
} from "../../../../types/collateral.ts";
import { Customer } from "../../../../types/customer.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { hasPermission } from "../../../../utils/user.ts";
import { GiroManagementLogModal } from "./GiroManagementLogModal.tsx";
import GiroManagementModal from "./GiroManagementModal/GiroManagementModal.tsx";
import GiroManagementToolbar from "./GiroManagementToolbar/GiroManagementToolbar.tsx";
import { useGiroManagementTableData } from "./hooks/useGiroManagementTableData.tsx";
import { getGiroManagementApiFilter, getGiroNumber } from "./utils.ts";

interface Props {
  company: Pick<Customer, "id" | "partnerCode"> | null | undefined;
}

const GiroManagementTable = ({ company }: Props) => {
  const [filter, setFilter] = useState<CollateralFilter>({});
  const [orderBy, setOrderBy] = useState<CollateralOrderBy>({
    createdAt: OrderBy.Asc,
  });
  const [selectedGiroManagement, setSelectedGiroManagement] =
    useState<Collateral | null>();

  const [giroModalOpened, { open: giroModalOpen, close: giroModalClose }] =
    useDisclosure(false);
  const [
    giroLogModalOpened,
    { open: giroLogModalOpen, close: giroLogModalClose },
  ] = useDisclosure(false);

  const [page, setPage] = useState<number>(1);

  const [{ data, fetching, error }, refresh] = useCollaterals({
    filter: { ...filter, customerId: { equals: company?.id } },
    orderBy,
    page,
  });

  const [{ data: olNumbersData }] = useGetOlNumbersList({
    customerId: company?.id,
  });

  const [{ data: soNumbersData }] = useGetSoNumbersList({
    customerId: company?.id,
  });

  const { user: currentUser } = useCurrentUserContext();

  const olNumbersList = (olNumbersData?.offerLetters ?? [])
    .map((item) => item.olNo)
    .filter((olNo) => !isNil(olNo)) as string[];
  const soNumbersList = (soNumbersData?.transactionHistory?.data ?? []).filter(
    (item) => !isNil(item.saleOrderNumber)
  ) as { id: number; saleOrderNumber: string }[];

  const giroManagementList: Collateral[] | undefined | null =
    data?.collaterals?.data;
  const pageCount = getPagesCount(data?.collaterals?.total);

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | DatesRangeValue | null) => {
      setFilter(getGiroManagementApiFilter({ [key]: value }));
    },
    []
  );

  const handleGiroModalClose = () => {
    setSelectedGiroManagement(null);
    giroModalClose();
  };

  const handleGiroLogModalClose = () => {
    setSelectedGiroManagement(null);
    giroLogModalClose();
  };

  const [, updateGiroManagement] = useMutationNotificationWrapper(
    useUpdateCollateral(),
    {
      success: {
        message: "Giro Management has been updated successfully.",
      },
    }
  );

  const [, createGiroManagement] = useMutationNotificationWrapper(
    useCreateCollateral(),
    {
      success: {
        message: "Giro Management has been added successfully.",
      },
    }
  );

  const handleGiroModalOpen = (value: Collateral) => {
    setSelectedGiroManagement(value);
    giroModalOpen();
  };

  const handleGiroLogModalOpen = (value: Collateral) => {
    setSelectedGiroManagement(value);
    giroLogModalOpen();
  };

  const handleUpdate = useCallback(
    (id: number, value: RawCollateral) => {
      const input = omit(value, [
        "id",
        "createdAt",
        "updatedAt",
        "customerId",
      ]) as UpdateCollateralInput;
      updateGiroManagement({ id, input }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [refresh, updateGiroManagement]
  );

  const handleCreate = useCallback(
    (input: CollateralModalType) => {
      createGiroManagement({
        input: {
          ...input,
          collateralNumber: getGiroNumber(
            input.giroType ?? GiroType.Giro,
            company?.partnerCode,
            data?.collaterals?.total
          ),
          customerId: company?.id,
        } as CreateCollateralInput,
      }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [
      createGiroManagement,
      company?.partnerCode,
      company?.id,
      data?.collaterals?.total,
      refresh,
    ]
  );

  const handleGiroManagementModalSubmit = (input: CollateralModalType) => {
    if (selectedGiroManagement) {
      handleUpdate(selectedGiroManagement.id, {
        ...input,
        customerId: company?.id,
      } as RawCollateral);
    } else {
      handleCreate(input as CollateralModalType);
    }
    handleGiroModalClose();
  };

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoLimitInfoPksLists
  );

  const [columns, rows] = useGiroManagementTableData({
    data: giroManagementList,
    canEdit: hasEditAccess,
    onOpenEditModal: handleGiroModalOpen,
    onOpenLogModal: handleGiroLogModalOpen,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  if (!company) {
    return;
  }

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Giro Management Lists data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <>
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">GIRO Management</SectionTitle>
        <GiroManagementToolbar
          canEdit={hasEditAccess}
          onModalOpen={giroModalOpen}
          onFilterChange={handleFilterChange}
        />
        <Table
          columns={columns}
          rows={rows}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          loading={fetching}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          onSortChange={handleSortChange}
        />
      </Flex>
      {giroModalOpened && (
        <GiroManagementModal
          value={selectedGiroManagement}
          opened={true}
          olNumbersList={olNumbersList}
          soNumbersList={soNumbersList}
          onSubmit={handleGiroManagementModalSubmit}
          onClose={handleGiroModalClose}
        />
      )}
      {giroLogModalOpened && selectedGiroManagement && (
        <GiroManagementLogModal
          collateralId={selectedGiroManagement.id}
          opened={true}
          onClose={handleGiroLogModalClose}
        />
      )}
    </>
  );
};

export default GiroManagementTable;
