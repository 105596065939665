import { Flex, Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import InfoTooltip from "../../../../../components/InfoTooltip/InfoTooltip.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import {
  LegalDocumentStatus,
  UpdateLegalDocumentInput,
} from "../../../../../graphql/generated.ts";
import {
  LegalDocument,
  toHumanReadableLegalDocumentType,
} from "../../../../../types/legalDocument.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { getLegalDocumentStatusOptions } from "../utils.ts";

interface Props {
  data: LegalDocument[] | null | undefined;
  canEdit: boolean;
  onEdit: (documentId: number, value: UpdateLegalDocumentInput) => void;
  onOpenEditModal: (document: LegalDocument) => void;
}

export function useLegalDocumentTableData({
  data,
  canEdit,
  onEdit,
  onOpenEditModal,
}: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "documentDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "documentDate",
          }}
        >
          Document Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "documentNo",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "documentNo",
          }}
        >
          Document No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "documentType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "documentType",
          }}
        >
          Document Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "documentFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "documentFile",
          }}
        >
          Document File
        </DatabaseMetadataPopover>
      ),
      width: 200,
    },
    {
      accessor: "expiryDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "expiryDate",
          }}
        >
          Document Expiry Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "signedDocumentFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "signedDocumentFile",
          }}
        >
          Signed Document File
        </DatabaseMetadataPopover>
      ),
      width: 200,
    },
    {
      accessor: "status",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LegalDocument",
            fieldName: "status",
          }}
        >
          <Flex align="center">
            <Text>Status</Text>
            <InfoTooltip tooltipText="Active status will be unavailable until Signed File is uploaded." />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "actions",
      title: "Actions",
      width: 200,
      hidden: !canEdit,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        documentDate: toLocalDate(item.documentDate),
        documentNo: item.documentNo,
        documentType: toHumanReadableLegalDocumentType(item.documentType),
        documentFile: <TableFilesDisplay files={[item.documentFile]} />,
        expiryDate: toLocalDate(item.expiryDate),
        signedDocumentFile: item?.signedDocumentFile && (
          <TableFilesDisplay files={[item.signedDocumentFile]} />
        ),
        status: (
          <Select
            data={getLegalDocumentStatusOptions(item?.signedDocumentFile)}
            value={item.status}
            withinPortal
            zIndex={100}
            size="s"
            placeholder="Select Status"
            disabled={!canEdit || !item.signedDocumentFile}
            onChange={(value) =>
              onEdit(item.id, { status: value as LegalDocumentStatus })
            }
          />
        ),
        actions: (
          <ActionIcon onClick={() => onOpenEditModal(item)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
      return row;
    });
  }, [data, canEdit, onEdit, onOpenEditModal]);

  return [columns, rows];
}
