import { Badge } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { PaymentStatus } from "../../../../graphql/generated.ts";
import {
  Invoice,
  toHumanReadablePaymentType,
} from "../../../../types/invoice/invoice.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  data: Invoice[] | null | undefined;
}

export function useInvoicesTableData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "createdAt",
          }}
        >
          Invoice Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "invoiceNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceNumber",
          }}
        >
          Invoice No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "250px",
    },
    {
      accessor: "saleOrderNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "saleOrderNumber",
          }}
        >
          SO No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "250px",
    },
    {
      accessor: "paymentType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentType",
          }}
        >
          Payment Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentMethod",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentMethod",
          }}
        >
          Payment Method
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitUsed",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "limitUsed",
          }}
        >
          Limit Used
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "300px",
    },
    {
      accessor: "totalInvoice",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "totalInvoice" }}
        >
          Total Invoice
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalAmountDue",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalAmountDue",
          }}
        >
          OSP
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "lateCharge",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "lateCharge",
          }}
        >
          Late Charge
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalAccountReceivable",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalAccountReceivable",
          }}
        >
          Total AR
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalPaid",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "totalPaid" }}
        >
          Total Paid Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "dpd",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "dpd" }}
        >
          DPD
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentStatus",
          }}
        >
          Payment Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "invoiceDueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceDueDate",
          }}
        >
          Invoice Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "lastPaymentDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "InvoicePayment",
            fieldName: "paidAt",
          }}
        >
          Paid Date
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "salesName" }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "hasException",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "hasException",
          }}
        >
          Has Exception
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "isRestructured",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "isRestructured",
          }}
        >
          Is Restructured
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
  ];

  const renderPaymentStatusColumn = useCallback((item: Invoice) => {
    let backgroundColor = "green";
    if (item.paymentStatus === PaymentStatus.Partial) {
      backgroundColor = "blue";
    } else if (item.paymentStatus === PaymentStatus.NotPaid) {
      backgroundColor = "red";
    }
    return (
      <Badge color={backgroundColor} size="sm" radius="xs">
        {toHumanReadable(item.paymentStatus)}
      </Badge>
    );
  }, []);

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        createdAt: item.createdAt,
        invoiceNumber: item.invoiceNumber,
        saleOrderNumber: item.saleOrderNumber,
        paymentMethod: toHumanReadable(item.paymentMethod, true),
        paymentType: toHumanReadablePaymentType(item.paymentType),
        limitUsed: item.limitUsed,
        totalInvoice: toCurrencyPrice(item.totalInvoice),
        totalAmountDue: toCurrencyPrice(item.totalAmountDue),
        lateCharge: toCurrencyPrice(item.lateCharge),
        totalAccountReceivable: toCurrencyPrice(item.totalAccountReceivable),
        totalPaid: toCurrencyPrice(item.totalPaid),
        dpd: toLocalNumber(item.dpd),
        paymentStatus: renderPaymentStatusColumn(item),
        invoiceDueDate: toLocalDate(item.invoiceDueDate),
        lastPaymentDate: toLocalDate(item.lastPayment?.paidAt),
        salesName: item.salesName,
        salesTeamName: item.salesTeamName,
        hasException: item.hasException ? "Yes" : "No",
        isRestructured: item.isRestructured ? "Yes" : "No",
      };
    });
  }, [data, renderPaymentStatusColumn]);

  return [columns, rows];
}
